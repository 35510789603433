import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import LogRocket from 'logrocket';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import { GET_USER, GET_VENDOR_PROFILE, THEME_TYPE } from 'graphql/query/user';
import {
  CHECK_USER,
  SET_NETWORK_STATUS,
  SET_NOTIFICATION,
} from 'graphql/mutation/user';

import { ThemeProvider } from '@material-ui/styles';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import theme from 'theme';

import Login from 'containers/Login';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import NotFound from 'containers/404';
import Customers from 'containers/Customers';
import Orders from 'containers/Orders';
import Products from 'containers/Products';
import Lists from 'containers/Lists';
import Deals from 'containers/Deals';
import List from 'containers/List';
import Settings from 'containers/Settings';
import Layout from 'components/shared/Layout';
import SalesReps from 'containers/SalesReps';
import SalesRepDetails from 'containers/SalesRep';
import Categories from 'containers/Categories';
import Campaigns from 'containers/Campaigns';
import ManageRecipientsTable from 'components/Campaigns/ManageRecipientsTable';
import SingleCampaign from 'components/Campaigns/SingleCampaign';
import CampaignReport from 'containers/CampaignReport';
import PdfCatalogs from 'containers/PdfCatalogs';
import PdfCatalog from 'containers/PdfCatalog';
import SpecialDaysPage from 'containers/SpecialDaysPage';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import Loader from 'components/shared/Loader';
import Newsletters from 'containers/Newsletters';
import NewsletterConstructor from 'containers/NewsletterConstructor';
import NewsletterRecipients from 'containers/NewsletterRecipients';
import Group from 'containers/Group';
import Groups from 'containers/Groups';

const Ingest = React.lazy(() => import('containers/Ingest'));
const OrderEmail = React.lazy(() => import('containers/OrderEmail'));
const Vendors = React.lazy(() => import('containers/Vendors'));
const CustomerCare = React.lazy(() => import('containers/Support'));
const ProductTypes = React.lazy(() => import('containers/ProductTypes'));

const AppRouter = () => {
  const [
    checkUser,
    { loading: loadingCheckUser, data: checkUserData },
  ] = useMutation(CHECK_USER);
  const [isOnline] = useMutation(SET_NETWORK_STATUS);
  const { loading, error, data } = useQuery(GET_USER);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const mixPanelHook = useMixPanel();
  const history = useHistory();

  const [getVendorProfileData, { data: vendorData }] = useLazyQuery(
    GET_VENDOR_PROFILE,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const {
    data: {
      theme: { type = 'light' },
    },
  } = useQuery(THEME_TYPE);

  const sessionStorageData = JSON.parse(sessionStorage.getItem('user'));
  const user = sessionStorageData?.user || data.user;

  React.useEffect(() => {
    const initApp = async () => {
      await checkUser();
      if (process.env.REACT_APP_ENV_NAME === 'prod') {
        LogRocket.init('wtyszf/wall-e-production');
      }
    };
    initApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (user?.userData?.accountId && !loadingCheckUser && checkUserData) {
      getVendorProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVendorProfileData, checkUserData, loadingCheckUser]);

  const updateNetwork = () => {
    isOnline({ variables: { isOnline: window.navigator.onLine } });
    setNotification({
      variables: {
        timeout: 4000,
        // eslint-disable-next-line camelcase
        message: `Your network status is ${
          window.navigator.onLine ? 'OnLine' : 'OffLine'
        }`,
        type: NOTIFICATION_STATUS.ALERT,
        isOpen: true,
      },
    });
    checkUser();
  };

  React.useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  React.useEffect(() => {
    if (user?.userData?.accountId) {
      LogRocket.identify(user?.userData?.accountId, {
        name: user?.userData?.name,
        email: user?.userData?.email,
        userId: user?.userData?.userId,
        accountId: user?.userData?.accountId,
        app: 'wall-e',
        isElectron: !!process.env.REACT_APP_IS_ELECTRON,
      });
      mixPanelHook.identify(user?.userData?.accountId);
      mixPanelHook.people.set({
        $name: user?.userData?.name,
        $email: user?.userData?.email,
        userId: user?.userData?.userId,
        vendorId: user?.userData?.accountId,
        vendorName: vendorData?.vendorAccount?.contactData?.businessName,
        app: 'wall-e',
        isElectron: !!process.env.REACT_APP_IS_ELECTRON,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    history.listen(incomingLocation => {
      mixPanelHook.track('Page View', { page: incomingLocation.pathname });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  React.useEffect(() => {
    if (user?.userData?.accountId && window?.Beacon) {
      LogRocket.getSessionURL(sessionURL => {
        window.Beacon('identify', {
          name: user?.userData?.name,
          email: user?.userData?.email,
          userId: user?.userData?.userId,
          logRocket: true,
          sessionURL,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Beacon, data]);

  if (loading || !data || loadingCheckUser || !checkUserData) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const userIsLoggedOut = !user?.userData?.accountId;
  const themeStyle = theme(type);

  if (sessionStorageData && userIsLoggedOut) {
    history.push('/logout');
  }

  const vendorHasFtpIntegration = vendorData?.vendorAccount?.ftpIntegration?.enabled
    || vendorData?.vendorAccount?.apiIntegration?.enabled
    || false;
  const vendor = vendorData?.vendorAccount;
  const { isAdmin } = user.userData;
  const isNewsletterEnabled =
    vendorData?.vendorAccount?.preferences?.newsletter?.enabled !== false;

  return (
    <ThemeProvider theme={themeStyle}>
      <ErrorBoundary>
        {userIsLoggedOut || user?.error ? (
          <Layout noHeader isUnauthorized>
            <Switch>
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="/reset-password" exact component={ResetPassword} />
              <Route
                path="/login"
                component={() => <Login error={user?.error} />}
              />
              <Redirect to="/login" />
            </Switch>
          </Layout>
        ) : (
          <Layout isAdmin={isAdmin}>
            <Switch>
              {isAdmin && <Route path="/support" exact component={CustomerCare} />}
              {isAdmin && <Route path="/ingest" exact component={Ingest} />}
              {isAdmin && (
                <Route
                  path="/create-vendor/:vendorId?"
                  exact
                  component={Vendors}
                />
              )}
              {isAdmin && (
                <Route
                  path="/order-email"
                  exact
                  component={() => <OrderEmail />}
                />
              )}

              <Route path="/reset-password" exact component={ResetPassword} />
              <Route
                path="/customers"
                exact
                render={() => (
                  <Customers
                    vendorHasFtpIntegration={vendorHasFtpIntegration}
                    isAdmin={isAdmin}
                  />
                )}
              />
              <Route path="/orders" exact component={Orders} />
              <Route
                path="/products/:section"
                exact
                render={() => (
                  <Products
                    vendorHasFtpIntegration={vendorHasFtpIntegration}
                    isAdmin={isAdmin}
                    vendor={vendor}
                  />
                )}
              />
              <Route
                path="/list/:listId"
                render={({ match, ...props }) => (
                  <List listId={match.params.listId} {...props} />
                )}
              />
              <Route path="/lists" exact component={Lists} />
              <Route
                path="/salesRep/:salesRepId"
                render={({ match, ...props }) => (
                  <SalesRepDetails id={match.params.salesRepId} {...props} />
                )}
              />
              <Route path="/salesReps" exact component={SalesReps} />
              <Route path="/deals" exact render={() => <Deals />} />
              <Route
                path="/settings"
                exact
                component={() => <Settings isAdmin={isAdmin} />}
              />
              <Route
                path="/categories"
                exact
                component={() => <Categories />}
              />
              <Route
                path="/product-types"
                exact
                component={() => <ProductTypes />}
              />
              <Route path="/campaigns" exact component={() => <Campaigns />} />
              <Route
                path="/campaign/:campaignId/edit-contacts"
                exact
                component={() => <ManageRecipientsTable />}
              />
              <Route
                path="/campaign/:campaignId/report"
                exact
                component={() => <CampaignReport />}
              />
              <Route
                path="/campaign/:campaignId"
                exact
                component={() => <SingleCampaign />}
              />
              <Route path="/pdfCatalogs" exact component={PdfCatalogs} />
              <Route
                path="/pdfCatalog/:pdfCatalogId"
                exact
                render={({ match, ...props }) => (
                  <PdfCatalog id={match.params.pdfCatalogId} {...props} />
                )}
              />
              <Route path="/delivery-days/:type?">
                <SpecialDaysPage />
              </Route>

              {/* NOTE: we need to use condition on each Route, wrapping all with <> fragment will ruin Redirect */}
              {isNewsletterEnabled && (
                <Route
                  path="/newsletter/:newsletterId/recipients"
                  exact
                  render={({ match, ...props }) => (
                    <NewsletterRecipients
                      newsletterId={match.params.newsletterId}
                      vendor={vendor}
                      {...props}
                    />
                  )}
                />
              )}
              {isNewsletterEnabled && (
                <Route
                  path="/newsletter/:newsletterId"
                  exact
                  render={({ match, ...props }) => (
                    <NewsletterConstructor
                      newsletterId={match.params.newsletterId}
                      vendor={vendor}
                      {...props}
                    />
                  )}
                />
              )}
              {isNewsletterEnabled && (
                <Route path="/newsletters" exact component={Newsletters} />
              )}
              <Route
                path="/group/:groupId"
                render={({ match, ...props }) => (
                  <Group groupId={match.params.groupId} {...props} />
                )}
              />
              <Route path="/groups" exact component={Groups} />

              <Route path="/" exact>
                <Redirect to="/customers" />
              </Route>
              <Redirect from="/login" to="/customers" />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        )}
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default AppRouter;
